import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import moment from 'moment'

Vue.filter('formatDate', function (value) {
  if (value) { //Jan. 18, 2020, 2:41 a.m
    return moment(String(value)).format('MMMM. DD, YYYY, HH:mm')
  }
})
Vue.config.productionTip = false
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
