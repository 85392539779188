<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list>
        <v-list-item-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item :to="{ name: 'home' }" exact>
            <v-list-item-action>
              <v-icon>mdi-home</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>首页</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          color="secondary"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            exact
            :to="'/tools/' + subItem.slug"
          >
            <v-list-item-content>
              <v-list-item-title v-text="subItem.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item :to="{ name: 'software' }" exact>
            <v-list-item-action>
              <v-icon>mdi-desktop-mac</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>桌面应用</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'extensions' }" exact>
            <v-list-item-action>
              <v-icon>mdi-puzzle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>扩展插件</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white" elevation="0" id="app-bar">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="d-flex align-center">
        <img src="./assets/logo.png" class="logo mr-4" width="32px" />
        <span>Ninety Eights</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mr-4 text--secondary" text :to="{ name: 'home' }" exact>首页</v-btn>
      <v-btn class="mr-4 text--secondary" text :to="{ name: 'tools' }">线上工具</v-btn>
      <v-btn class="mr-4 text--secondary" text :to="{ name: 'software' }">桌面应用</v-btn>
      <v-btn class="mr-4 text--secondary" text :to="{ name: 'extensions' }">扩展插件</v-btn>
    </v-app-bar>

    <v-main>
      <router-view name="global"></router-view>

      <v-container>
        <transition :name="transitionName" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  methods: {
    siderNav() {
      if (window.innerWidth < 1264) {
        this.mini = false;
        this.drawer = !this.drawer;
      } else {
        this.mini = !this.mini;
      }
    },
  },

  // beforeRouteUpdate(to, from, next) {
  //   const toDepth = to.path.split("/").length;
  //   const fromDepth = from.path.split("/").length;
  //   this.animation = toDepth < fromDepth ? "slide-right" : "slide-left";
  //   next();
  // },

  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;
      if (transitionName === "slide") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || "fade";

      next();
    });
  },
  data: () => ({
    transitionName: "fade",
    drawer: window.innerWidth < 1264 ? false : true,
    items: [
      {
        action: "mdi-wrench",
        title: "线上工具",
        active: true,
        url: "tools",
        isParent: true,
        items: [],
      },
    ],
  }),

  mounted() {
    this.$axios
      .get("/api/tools/")
      .then((res) => (this.items[0].items = res.data));
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.v-image__image.v-image__image--cover {
  filter: blur(4px);
}

#app-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
