import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FF9800',
                // secondary: '#616161',
                // accent: '#8c9eff',
                // error: '#b71c1c',
            },
        },
    },
});