<template>
  <div class="home">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="4"
        v-for="(item, index) in items"
        v-if="items.length > 0"
        :key="index"
      >
        <v-card class="mx-auto" elevation="0" outlined>
          <v-img
            class="white--text align-end"
            height="200px"
            :src="
              item.image || 'https://cdn.vuetifyjs.com/images/cards/docks.jpg'
            "
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.6)"
          >
            <v-card-title>{{ item.title }}</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-2"
            >Updated at: {{ item.updated | formatDate }}</v-card-subtitle
          >
          <v-card-text class="text--primary pb-0">
            <div class="max-line">{{ item.description }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="item.type === 'tools'"
              color="orange"
              text
              :to="'/tools/' + item.slug"
              >点击跳转</v-btn
            >
            <v-btn
              v-else-if="item.type === 'software'"
              color="orange"
              text
              :href="item.download_file"
              >点击下载</v-btn
            >
            <v-btn v-else color="orange" text :href="item.link" target="_blank"
              >点击进入</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "home",
  data: () => ({
    items: [],
  }),
  mounted() {
    this.$axios.get("/api/tools/").then((res) => {
      // this.items.push(Object.assign({ type: "tools" }, ...res.data));
      res.data.map((item) =>
        this.items.push(Object.assign({ type: "tools", ...item }))
      );
      this.$axios.get("/api/software/").then((res) => {
        res.data.map((item) =>
          this.items.push(Object.assign({ type: "software", ...item }))
        );
      });
      this.$axios.get("/api/extensions/").then((res) => {
        res.data.map((item) =>
          this.items.push(Object.assign({ type: "extensions", ...item }))
        );
      });
    });
  },
};
</script>

<style lang="scss">
.container,
.home {
  height: 100%;
}
</style>
