import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const title = 'Ninety Eights'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: title,
      transitionName: 'fade'
    }
  },
  {
    path: '/about/',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: `${title} | About`,
      transitionName: 'fade'
    }
  },
  {
    path: '/extensions/',
    name: 'extensions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Extensions.vue'),
    meta: {
      title: `${title} | 扩展插件`,
      transitionName: 'slide'
    }
  },
  {
    path: '/tools/',
    name: 'tools',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Tools.vue'),
    meta: {
      title: `${title} | 线上工具`,
      transitionName: 'slide'
    },
  },
  {
    path: '/tools/qr-code',
    component: () => import('../views/Qrcode.vue'),
    meta: {
      title: `${title} | 二维码生成`,
      transitionName: 'slide'
    }
  },
  {
    path: '/tools/link-transform',
    component: () => import('../views/LinkTransform.vue'),
    meta: {
      title: `${title} | 链接转换`,
      transitionName: 'slide'
    }
  },
  {
    path: '/tools/extract-link',
    component: () => import('../views/ExtractLink.vue'),
    meta: {
      title: `${title} | 链接提取`,
      transitionName: 'slide'
    }
  },
  {
    path: '/tools/open-multiple-link',
    component: () => import('../views/OpenMultipleLink.vue'),
    meta: {
      title: `${title} | 打开多个链接`,
      transitionName: 'slide'
    }
  },
  {
    path: '/tools/browser-extensions',
    component: () => import('../views/BrowserExtensions.vue'),
    meta: {
      title: `${title} | 插件链接`,
      transitionName: 'slide'
    }
  },
  {
    path: '/software/',
    name: 'software',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Software.vue'),
    meta: {
      title: `${title} | 桌面应用`,
      transitionName: 'slide'
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'register') {
    // 隐藏侧边栏
  }
  document.title = to.meta.title || title
  next()
})

export default router
